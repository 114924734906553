.App {
	color: #2B2B2B;
}

p {
	margin: 0;
	padding: 0;
}

header {
	background-color: #2B00E0;
	width: auto;
	height: auto;
	margin-left: 0;
	margin-right: 0;
}

header h1 {
	margin: 0;
	padding-top: 29px;
	padding-bottom: 29px;
	padding-left: 10px;
	font-weight: normal;
	font-size: 24px;
	color: #FFFFFF;
}

.next-bus {
	margin-top: 40px;
	margin-bottom: 32px;
	text-align: center;
}

.next-bus .label {
	margin-bottom: 10px;
	font-size: 20px;
	color: #717171;
}

.next-bus .time span {
	vertical-align: middle;
}

.next-bus .time .hhmm {
	font-size: 45px;
	color: #393939;
}

.next-bus .time .eta {
	font-size: 20px;
	color: #717171;
	padding-left: 12px;
}

.time-table {
	margin-left: 0;
	margin-right: 0;
	margin-top: 30px;
	margin-bottom: 20px;
	padding-left: 23px;
	padding-right: 23px;
	width: auto;
	font-size: 20px;
}

.time-table table {
	border: 1px solid #2B1DE0;
	border-radius: 8px;
	width: 100%;
	border-spacing: 0;
}

.time-table tr {
	margin: 0;
	padding: 0;
}

.time-table td {
	margin: 0;
	padding: 0;
	border-right: 1px solid #2B1DE0;
	border-bottom: 1px solid #2B1DE0;
}

.time-table td:last-child {
	border-right: none;
}

.time-table tr:last-child td {
	border-bottom: none;
}

.time-table .departing-station {
	padding-top: 6px;
	padding-bottom: 6px;
	text-align: center;
	font-size: 16px;
}

.time-table .past-buses button {
	all: unset;
	width: 100%;
	padding-top: 3px;
	padding-bottom: 3px;
	background-color: #DEDCFA;
	text-align: center;
}

.time-table .hour {
	padding: 16px;
	text-align: center;
	vertical-align: top;
	width: 1px;
}

.time-table .past-hour {
	color: #D3D3D3;
}

.time-table .arrivals {
	text-align: left;
	padding-left: 16px;
	padding-bottom: 16px;
	padding-right: 10px;
}

.time-table .arrivals span {
	display: inline-block;
	margin-right: 5px;
	margin-top: 16px;
	padding-bottom: 5px;
	line-height: 1;
	width: 40px;
	text-align: center;
}

.time-table .arrivals .past-bus {
	color: #D3D3D3;
}

.chooser {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin-top: 32px;
	margin-bottom: 30px;
}

.chooser .switch {
	display: flex;
	margin-left: 14px;
	margin-right: 14px;
}

.chooser .switch input {
	display: none;
}

.chooser .switch label {
	display: block;
	font-size: 20px;
	border: 1px solid #2B1DE0;
	padding: 18px;
	border-radius: 8px 0 0 8px;
}

.chooser .switch label:last-child {
	border-left: none;
	border-radius: 0 8px 8px 0;
}

.chooser .switch input:checked + label {
	background-color: #DEDCFA;
	color: #2B2B2B;
}

.lines {
	margin-left: 32px;
	margin-right: 32px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.lines p {
	line-height: 1.5em;
}

@media screen and (max-width: 400px) {
	header h1 {
		font-size: 6vw;
	}

	.chooser {
		margin-top: 8vw;
		margin-bottom: 7.5vw;
		margin-left: 5.75vw;
		margin-right: 5.75vw;
		justify-content: space-between;
	}

	.chooser .switch {
		margin: 0;
	}

	.chooser .switch label {
		font-size: 5vw;
		padding: 4.5vw;
	}
}

@media screen and (min-width: 1024px) {
	.time-table {
		width: 800px;
		margin-left: auto;
		margin-right: auto;
	}
}
